import { Map } from "ol";
import { Control } from "ol/control";
import { Coordinate } from "ol/coordinate";
import * as OlProj from 'ol/proj';
import { MapComponent } from "./map.component";

/**
 * New control for OpenLayer : refresh the shops
 */
export class RefreshControl extends Control {
    // Reference to the Map component
    private mapComponent: MapComponent;

    /**
     * Constructor
     * @param mapComponent 
     */
    constructor(mapComponent: MapComponent) {  
        let locate = document.createElement('button')
        locate.innerHTML = '<i class="fa-solid fa-burger"></i>';
        let elem = document.createElement('div')
        elem.className = 'jfe-map-control-refresh-position button ol-control'
        elem.append(locate)

        super({
            element: elem
        })
        this.mapComponent = mapComponent;
        elem.addEventListener('click', this.handleLocate.bind(this),false);
    }

    /**
     * Called when the button is clicked
     */
    handleLocate() {
        this.mapComponent.mapControlRefreshShopsRequest();
    }
}