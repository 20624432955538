// Angular
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

// PrimeNg
import { ConfirmationService, MessageService } from 'primeng/api';

// OpenLayers
import { toLonLat } from 'ol/proj';
import { Coordinate } from 'ol/coordinate';

// Google
import { GoogleAnalyticsService } from 'ngx-google-analytics';

// Components & Services
import { BaseComponent } from '../base.component';
import { IRouteInfo, MapComponent } from '../map/map.component';
import { IPlace } from '../search/search.component';
import { DataService, ICompany, ICookieConsent, ISettings, IShop } from '../service/data.service';
import { CookieComponent } from '../cookie/cookie.component';

// Configuration
import { environment } from 'src/environments/environment';

// i18n
import { TranslateService } from '@ngx-translate/core';

interface IShopResponse {
  success: boolean,
  message: string,
  shops: IShop[]
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  providers: [MessageService, ConfirmationService, { provide: Window, useValue: window }]
})
export class MainComponent extends BaseComponent implements OnInit {
  public environment = environment;
  
  /**
   * References to UI components
   */

  // Reference to the map component
  @ViewChild('mapComponent') mapComponent: MapComponent;
  
  // Reference to the cookie component
  @ViewChild('cookieComponent') cookieComponent: CookieComponent;

  /**
   * Variables linked to observables
   */

  // Companies
  public companies: ICompany[] = [];

  // Cookie consent
  public cookieConsent: ICookieConsent;

  // Settings
  public settings: ISettings;

  /**
   * Local variables
   */

  // Shops
  public shops: IShop[];
  public filteredShops: IShop[] = [];
  public selectedShop: IShop = null;
  public companiesCount: number = 0;
  public checkedCompaniesCount: number = 0;

  // Settings dialog box
  public displaySettings: boolean = false;
  public settingsRadius: number = 0;
  public settingsMaxResults: number = 0;

  // Companies carousel
  public responsiveOptions = [
    {
      breakpoint: '5000px',
      numVisible: 24,
      numScroll: 6
    },
    {
      breakpoint: '2048px',
      numVisible: 24,
      numScroll: 6
    },
    {
      breakpoint: '1500px',
      numVisible: 12,
      numScroll: 4
    },
    {
      breakpoint: '1024px',
      numVisible: 9,
      numScroll: 3
    },
    {
      breakpoint: '768px',
      numVisible: 6,
      numScroll: 2
    },
    {
      breakpoint: '560px',
      numVisible: 4,
      numScroll: 1
    }
  ];

  /**
   * Display variables
   */

  // Display the legal terms
  public displayLegal: boolean = false;

  // --------------------
  //  CONSTRUCTOR & INIT
  // --------------------

  /**
   * Constructor
   * @param http 
   * @param messageService 
   * @param confirmationService 
   * @param data 
   */
  constructor(
    protected http: HttpClient,
    protected messageService: MessageService,
    protected confirmationService: ConfirmationService, 
    protected data: DataService,
    protected gaService: GoogleAnalyticsService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private window: Window
  ) { 
    super(http, messageService, confirmationService, data, gaService);
  }

  /**
   * Init
   */
  ngOnInit(): void {
    // Link to the companies list
    this.data.companiesObservable.subscribe(companies => 
      this.companies = companies);

    // Link to the cookie consent
    this.data.cookieConsentObservable.subscribe(cookieConsent => {
      this.cookieConsent = cookieConsent;
    });

    // Link to the settings
    this.data.settingsObservable.subscribe(settings => {
      this.settings = settings;
    });

    // Get the country code
    this.route.params.subscribe(params => {
      let redirect: boolean = false;
      let country_code = params['country-code'] ?? '';
      let language = params['language'] ?? '';

      // Not a supported country
      if (environment.supportedCountries.find(c => c.code === country_code) === undefined) {
        country_code = environment.defaultCountry;
        redirect = true;
      }

      // Not a supported language
      if (environment.supportedLanguages.find(l => l.code === language) === undefined) {
        language = environment.defaultLanguage;
        redirect = true;
      }

      // If any is default or incorrect, redirect
      if (redirect) {
        this.router.navigate(['', language, country_code]);
        return;
      }

      // Set the country code & language as a setting
      this.settings.country_code = country_code;
      this.document.documentElement.lang = language; 
      this.settings.language = language;
      this.data.saveSettings(this.settings);
    });
  }

  /**
   * Refresh after a localisation parameter has changed
   */
  private refreshLocalisationParameters() {
    // Set the language
    this.translate.use(this.settings.language);
    // Load the companies
    this.loadCompanies();
    // Analytics page viewed
    this.sendGoogleAnalyticsPageView('/', 'Main');
  }

  ngOnDestroy() {
  }

  /**
   * After the view initialisation
   */
  ngAfterViewInit(): void {
    this.refreshLocalisationParameters();
  }

  // -----------
  //  API CALLS
  // -----------

  /**
   * Load companies
   */
  private loadCompanies() {
    this.http.get<ICompany[]>(environment.apiEndpoint+'/api/getCompanies/' + this.settings.country_code).subscribe({
      next: (result) => {
        let checkedCompaniesCount = 0;
        let companies: ICompany[] = [];
        result.forEach(item => {
          item.icon_url = "../assets/image/icon/" + item.code + ".png";
          item.marker_url = "../assets/image/marker/" + item.code + ".png";
          item.checked = this.settings.excluded_companies.find(id => id === item.id) === undefined;
          if (item.checked) {
            checkedCompaniesCount++;
          }
          companies.push(item);
        });
        this.checkedCompaniesCount = checkedCompaniesCount;
        this.data.setCompanies(companies);

        // Now we can (try to) focus on the user's current location
        this.mapComponent.mapControlCenterOnCurrentLocation();
      },
      error: (error) => {
        this.httpErrorMessage(error);
      }
    });
  }

  /**
   * Search for shops
   */
  private searchShops(centerLonLat: Coordinate) {
    let formData = new FormData();
    formData.append('lat', centerLonLat[1]+'');
    formData.append('lng', centerLonLat[0]+'');
    formData.append('distance', this.settings.search_radius+'');
    formData.append('maxresults', this.settings.max_results+'');

    // Post
    this.http.post<IShopResponse>(environment.apiEndpoint+'/api/getShops/' + this.settings.country_code, formData).subscribe(
      result => {
        if (result.success) {
          let shops: IShop[] = [];
          result.shops.forEach(shop => {
            let company = this.companies.find(c => c.id === shop.company_id);
            shop.company_title = company.title;
            shop.icon_url = company.icon_url;
            shop.marker_url = company.marker_url;
            shop.distance_str = parseFloat(shop.distance+'').toFixed(2)
            shop.real_distance_str = '';
            shop.duration_str = '';
            shops.push(shop);
          });
          this.shops = shops;
          this.filterShops();
        }
      }, 
      error => {
        this.httpErrorMessage(error);
      }
    );
  }

  /**
   * Filter shops from the current shop list
   */
  private filterShops() {
    this.selectedShop = null;
    let filteredShops: IShop[] = [];
    this.shops.forEach(shop => {
      let company = this.companies.find(c => c.id === shop.company_id);
      if (company.checked) {
        filteredShops.push(shop);
      }
    });
    this.filteredShops = filteredShops;
    this.mapComponent.buildMarkersLayers(this.companies, this.filteredShops);
  }

  // --------
  //  COMMON
  // --------

  /**
   * Selects a shop
   * @param shop
   */
  private shopSelect(shop: IShop) {
    this.mapComponent.selectShop(shop);

    let myEl = document.getElementById("shop_" + shop.id);
    myEl.scrollIntoView({
      block: "start",
      behavior: "smooth"
    });

    this.selectedShop = shop;
  }

  /**
   * Format a number of seconds to hours & minutes
   * @param totalSeconds 
   * @returns 
   */
  private formatSeconds(totalSeconds: number): string {
    const totalMinutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    let result = '';
    if (hours>0) {
      result = hours + 'h ';
    }
    result += minutes + 'min';
    return result;
  }

  // ---------
  //  ACTIONS
  // ---------

  /**
   * Company toggle visibility
   * @param company_id 
   */
  public onCompanyToggle(company_id: number) {
    let company = this.companies.find(c => c.id === company_id);
    company.checked = !company.checked;
    let checkedCompaniesCount = 0;
    let unchecked = [];
    this.companies.forEach(c => {
      if (c.checked) { 
        checkedCompaniesCount++; 
      } else {
        unchecked.push(c.id);
      }
    });
    this.settings.excluded_companies = unchecked;
    this.data.saveSettings(this.settings);
    this.checkedCompaniesCount = checkedCompaniesCount;
    this.filterShops();
  }

  /**
   * Check/Uncheck all companies
   * @param checked 
   */
  public onCompanyCheckAll(checked: boolean) {
    let unchecked = [];
    this.companies.forEach(company => {
      company.checked = checked;
      if (!checked) {
        unchecked.push(company.id);
      }
    });
    this.settings.excluded_companies = unchecked;
    this.data.saveSettings(this.settings);
    this.checkedCompaniesCount = checked ? this.companies.length : 0;
    this.filterShops();
  }

  /**
   * Select a shop from the list
   * @param shop 
   */
  public onShopLocate(shop: IShop) {
    this.shopSelect(shop);
  }

  /**
   * Shows the cookie consent box
   */
  public onShowCookieConsent() {
    this.cookieComponent.show(true);
  }

  /**
   * Display the settings dialog box
   */
  public onShowSettings() {
    this.settingsRadius = this.settings.search_radius;
    this.settingsMaxResults = this.settings.max_results;
    this.displaySettings = true;
  }

  /**
   * Save settings
   */
  public onSaveSettings() {
    this.settings.search_radius = this.settingsRadius;
    this.settings.max_results = this.settingsMaxResults;
    this.data.saveSettings(this.settings);
    this.displaySettings = false;
  }

  /**
   * Set to current location
   */
  public onSetCurrentLocation() {
    this.mapComponent.mapControlCenterOnCurrentLocation();
  }

  // ---------------
  //  MAP CALLBACKS
  // ---------------

  /**
   * User selected a place, we have to center the map on this place
   * It will eventually call the "onMapCenterChangedLonLat" callback
   * @param $event 
   */
  public onPlaceSelected($event: IPlace) {
    this.mapComponent.center($event.latitude, $event.longitude, true);
  }

  /**
   * The map center has changed
   * @param $event
   */
  public onMapCenterChangedLonLat($event: Coordinate) {
    this.searchShops($event);
  }

  /**
   * Search for shops again
   */
  public onMapRefreshShopsRequest() {
    this.searchShops(toLonLat(this.mapComponent.map.getView().getCenter()));
  }

  /**
   * A marker has been clicked
   * @param $event 
   */
  public onMapMarketClick($event) {
    let shop = this.shops.find(s => s.id === $event);
    this.shopSelect(shop);
  }

  /**
   * Route information has been found for a shop
   * @param $event 
   */
  public onMapRouteInfo($event: IRouteInfo) {
    let shopIndex = this.shops.findIndex(s => s.id === $event.id);
    if (shopIndex < 0) {
      return;
    }

    this.shops[shopIndex].real_distance_str = parseFloat($event.distance/1000+'').toFixed(2);
    this.shops[shopIndex].duration_str = this.formatSeconds($event.duration);
  }

  // -----------------
  //  OTHER CALLBACKS
  // -----------------

  /**
   * Country has changed
   * @param $event 
   */
  public onCountryChanged($event) {
    this.data.saveSettings(this.settings);
    this.router.navigate(['', this.settings.language, this.settings.country_code]);
    this.refreshLocalisationParameters();
  }

  /**
   * Language has changed
   * @param $event 
   */
  public onLanguageChanged($event) {
    this.data.saveSettings(this.settings);
    this.router.navigate(['', this.settings.language, this.settings.country_code]);
    this.refreshLocalisationParameters();
  }
}