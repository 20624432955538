import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ConfirmationService, MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { DataService } from './service/data.service';

@Component({
  selector: 'app-base',
  template: ''
})
export class BaseComponent implements OnInit {

  constructor(
    protected http: HttpClient, 
    protected messageService: MessageService, 
    protected confirmationService: ConfirmationService,
    protected data: DataService,
    protected gaService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
  }

  /**
   * Generic error message display
   * @param title
   * @param message 
   */
      protected errorMessage(title: string, message: string) {
      this.messageService.add({severity:'error', summary: title, detail: message});
  }

  /**
   * Generic HTTP error message display
   * @param title
   * @param message 
   */
    protected httpErrorMessage(error: any) {
      if (error instanceof HttpErrorResponse) {
          this.messageService.add({severity:'error', summary: error.statusText, detail: error.message});    
      } else {
          this.messageService.add({severity:'error', summary: 'Erreur', detail: error});
      }
  }

  /**
   * Generic success message display
   * @param title
   * @param message 
   */
  protected successMessage(title: string, message: string) {
      this.messageService.add({severity:'success', summary: title, detail: message});
  }

  /**
   * Set Google Analytics consent
   * @param consent Enable/Disable Google Analytics consent
   */
  protected setGoogleAnalyticsConsent(consent: boolean) {
    if (consent === true) {
      this.gaService.gtag('consent', 'update', {
        'ad_storage': 'granted',
        'analytics_storage': 'granted'
      });
    } else {
      this.gaService.gtag('consent', 'update', {
        'ad_storage': 'denied',
        'analytics_storage': 'denied'
      });
    }
  }

  /**
   * Sends Google Analytics page_view event
   * @param path 
   * @param title 
   */
  protected sendGoogleAnalyticsPageView(path: string, title: string) {
    this.gaService.gtag('event', 'page_view', {
      page_title: title,
      page_location: environment.siteUrl,
      page_path: path,
      send_to: environment.googleMeasureId
    })
  }
}
