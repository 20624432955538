// Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule }   from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

// PrimeNG
import { StyleClassModule } from 'primeng/styleclass';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CarouselModule } from 'primeng/carousel';
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { SliderModule } from 'primeng/slider';
import { DropdownModule } from 'primeng/dropdown';

// FontAwesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// Translations
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

// Components
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { MapComponent } from './map/map.component';
import { BaseComponent } from './base.component';
import { SearchComponent } from './search/search.component';
import { CookieComponent } from './cookie/cookie.component';

// Google
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

// Configuration
import { environment } from '../environments/environment';
import { DefaultComponent } from './default/default.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    MapComponent,
    BaseComponent,
    SearchComponent,
    CookieComponent,
    DefaultComponent
  ],
  imports: [
    // Angular
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    RouterModule,

    // PrimeNG
    StyleClassModule,
    MenuModule,
    ButtonModule,
    ToastModule,
    AutoCompleteModule,
    CarouselModule,
    TabViewModule,
    DialogModule,
    SliderModule,
    DropdownModule,

    // FontAwsome
    FontAwesomeModule,

    // Translate
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      },
      // compiler configuration
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),

    // Google
    NgxGoogleAnalyticsModule.forRoot(environment.googleMeasureId, 
      [
        {
          command: 'config',
          values: [
            environment.googleMeasureId,
            {
              send_page_view: false
            }
          ]
        },
        {
          command: 'config',
          values: [
            environment.googleMeasureId,
            {
              debug_mode: environment.googleAnalyticsDebug
            }
          ]
        },
        {
          command: 'consent',
          values: [
            'default',
            {
                'ad_storage': 'denied',
                'analytics_storage': 'denied'
            }
          ]
        }
      ]
    ),
    NgxGoogleAnalyticsRouterModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
