import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

// Companies
// ---------

export interface ICompany {
  id: number,
  code: string,
  title: string,
  total: number,
  icon_url?: string,
  marker_url?: string,

  // Computed
  checked: boolean
}

export interface IShop {
  id: number,
  company_id: number,
  country_id: number,
  shop_id: string,
  name: string,
  address: string,
  postcode: string,
  city: string,
  phone: string,
  lat: number,
  lng: number,
  is_open: number,
  is_temp_closed: number,
  url: string,
  distance: number,

  // Computed
  company_title?: string,
  icon_url?: string,
  marker_url?: string,
  distance_str?: string,

  // Route
  real_distance_str?: string,
  duration_str?: string
}

export interface ICookieConsent {
  update_date: Date,
  analytics: boolean
}

export interface ISettings {
  search_radius: number,
  max_results: number,
  excluded_companies: number[],
  country_code: string,
  language: string
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public readonly version: string = '20221222000001';

  // Observables
  private companies = new BehaviorSubject<ICompany[]>(null);
  companiesObservable = this.companies.asObservable();

  private cookieConsent = new BehaviorSubject<ICookieConsent>(null);
  cookieConsentObservable = this.cookieConsent.asObservable();

  private settings = new BehaviorSubject<ISettings>(null);
  settingsObservable = this.settings.asObservable();

  // --------------------
  //  CONSTRUCTOR & INIT
  // --------------------

  /**
   * Constructor
   */
  constructor() { 
    // Get cookie consent information
    let cookieConsent: ICookieConsent = JSON.parse(localStorage.getItem('cookieConsent'+this.version)) || this.getDefaultCookieConsent();
    this.cookieConsent.next(cookieConsent);
    
    // Get settings
    let settings: ISettings = JSON.parse(localStorage.getItem('settings'+this.version)) || this.getDefaultSettings();
    this.settings.next(settings);
  }

  // ----------
  //  DEFAULTS
  // ----------

  /**
   * Default cookie consent
   * @returns
   */
  private getDefaultCookieConsent(): ICookieConsent {
    return {
      update_date: null,
      analytics: false
    }
  }

  /**
   * Default settings
   */
  private getDefaultSettings(): ISettings {
    return {
      max_results: environment.maxResultCount,
      search_radius: environment.searchRadiusKm,
      excluded_companies: [],
      country_code: environment.defaultCountry,
      language: environment.defaultLanguage
    }
  }

  // -------------
  //  OBSERVABLES
  // -------------

  /**
   * Updates the companies
   * @param companies 
   */
  public setCompanies(companies: ICompany[]) {
    this.companies.next(companies);
  }

  /**
   * Updates & saves the cookie consent policy
   * @param cookieConsent 
   */
  public saveCookieConsent(cookieConsent: ICookieConsent) {
    this.cookieConsent.next(cookieConsent);
    localStorage.setItem('cookieConsent'+this.version, JSON.stringify(cookieConsent));
  }

  /**
   * Updates & saves the settings
   * @param cookieConsent 
   */
  public saveSettings(settings: ISettings) {
    this.settings.next(settings);
    localStorage.setItem('settings'+this.version, JSON.stringify(settings));
  }
}
