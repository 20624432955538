import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { DataService, ICookieConsent } from '../service/data.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent implements OnInit {
    
  /**
   * Variables linked to observables
   */

  // Cookie consent
  public cookieConsent: ICookieConsent;

  /**
   * Local variables
   */

  // Is the box currently displayed ?
  public display = false;
  
  // --------------------
  //  CONSTRUCTOR & INIT
  // --------------------

  /**
   * Constructor
   * @param data 
   */
  constructor(
    protected data: DataService,
    protected gaService: GoogleAnalyticsService,
    protected cookieService: CookieService
  ) { 
  }

  ngOnInit(): void {
    // Link to the cookie consent
    this.data.cookieConsentObservable.subscribe(cookieConsent => {
      this.cookieConsent = cookieConsent;
      if (cookieConsent.update_date === null) {
        this.display = true;
      }
      this.updateGoogleAnalytics();
    });
  }

  /**
   * Show or hide the cookie consent box
   * @param display 
   */
  public show(display: boolean) {
    this.display = display;
  }

  // -------
  //  TOOLS
  // -------

  /**
   * Updates the Google Analytics consent depending on the user will
   */
  private updateGoogleAnalytics() {
    if (this.cookieConsent.analytics === true) {
      this.gaService.gtag('consent', 'update', { 'ad_storage': 'granted', 'analytics_storage': 'granted' });
    } else {
      this.gaService.gtag('consent', 'update', { 'ad_storage': 'denied', 'analytics_storage': 'denied' });
      // We should delete cookies now so the user won't think they are still used
      let cookies: {} = this.cookieService.getAll();
      for (let key in cookies) {
        if (key.startsWith('_ga')) {
          this.cookieService.delete(key, '/', environment.siteDomain, false);
        }
      }
    }
  }

  // ---------
  //  ACTIONS
  // ---------

  /**
   * Saves the changes
   */
  private saveCookieConsent() {
    this.data.saveCookieConsent(this.cookieConsent);
    this.show(false);
  }

  /**
   * Decline cookies button has been pressed
   */
  public onDecline() {
    this.cookieConsent.update_date = new Date();
    this.cookieConsent.analytics = false;
    this.saveCookieConsent();
    this.updateGoogleAnalytics();
  }

  /**
   * Authorise cookies button has been pressed
   */
  public onAuthorise() {
    this.cookieConsent.update_date = new Date();
    this.cookieConsent.analytics = true;
    this.saveCookieConsent();
    this.updateGoogleAnalytics();
  }
}
