import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { BaseComponent } from '../base.component';
import { DataService } from '../service/data.service';
import { environment } from '../../environments/environment';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

export interface IPlace {
  label: string,
  latitude: number,
  longitude: number
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class SearchComponent extends BaseComponent implements OnInit {
  // Callback to be called when a place is selected
  @Output() onPlaceSelected: EventEmitter<IPlace> = new EventEmitter<IPlace>();

  // List of places
  public places: IPlace[] = [];
  
  // --------------------
  //  CONSTRUCTOR & INIT
  // --------------------

  /**
   * Constructor
   * @param http 
   * @param messageService 
   * @param confirmationService 
   * @param data 
   */
   constructor(
    protected http: HttpClient, 
    protected messageService: MessageService, 
    protected confirmationService: ConfirmationService, 
    protected data: DataService,
    protected gaService: GoogleAnalyticsService
  ) { 
    super(http, messageService, confirmationService, data, gaService);
  }

  /**
   * OnInit
   */
  ngOnInit(): void {
  }

  /**
   * Search action, called by the AutoComplete
   * @param $event query contains the text
   */
  public onCompleteMethod($event) {
    // Calls the Nominatim API to get matches
    this.http.get<any[]>(environment.nominatimEndPoint+'/search?format=json&countrycodes=fr&q=' + $event.query).subscribe(
      places => {
        let values: IPlace[] = [];
        places.forEach(place => {
          values.push({label: place.display_name, latitude: +place.lat, longitude: +place.lon});
        });
        this.places = values;
      }
    );
  }

  /**
   * A new place has been selected
   * @param $event IPlace instance
   */
  public onSelect($event) {
    this.onPlaceSelected.emit($event);
  }
}
