import { Control } from "ol/control";
import { MapComponent } from "./map.component";

/**
 * New control for OpenLayer : gets the current location
 */
export class LocateControl extends Control {
    // Reference to the Map component
    private mapComponent: MapComponent;

    /**
     * Constructor
     * @param mapComponent 
     */
    constructor(mapComponent: MapComponent) {  
        let locate = document.createElement('button')
        locate.innerHTML = '<i class="fa-solid fa-location-crosshairs"></i>';
        let elem = document.createElement('div')
        elem.className = 'jfe-map-control-location-position button ol-control'
        elem.append(locate)

        super({
            element: elem
        })
        this.mapComponent = mapComponent;
        elem.addEventListener('click', this.handleLocate.bind(this),false);
    }

    /**
     * Called when the button is clicked
     */
    handleLocate() {
        this.mapComponent.mapControlCenterOnCurrentLocation();
    }
}