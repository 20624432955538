import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { DataService, ISettings } from '../service/data.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

  private settings: ISettings;
  private initDone: boolean = false;
  
  constructor(
    private translate: TranslateService,
    private router: Router,
    private data: DataService
  ) { 
  }

  ngOnInit(): void {
    this.translate.setDefaultLang(environment.defaultLanguage);

    // Link to the settings
    this.data.settingsObservable.subscribe(settings => {
      this.settings = settings;
      // Redirect to the default country only the first time
      if (!this.initDone) {
        this.router.navigate(['', settings.language, settings.country_code]);
        this.initDone = true;
      }
    });
  }
}
