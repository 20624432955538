export const environment = {
  // Environment
  production: true,
  siteDomain: '.junkfoodemergency.com',

  // Front
  siteUrl: 'https://www.junkfoodemergency.com/',
  siteFullDomain: 'www.junkfoodemergency.com',

  // API
  apiEndpoint: 'https://back.junkfoodemergency.com',

  // Map settings
  zoomAfterCenter: 13,
  initialZoom: 5,
  maxZoom: 21,

  // Search settings
  searchRadiusKm: 30,
  maxResultCount: 50,

  // Localisation
  mapInitialCenter: { fr: [2.213749, 46.227638] },
  supportedCountries: [ 
    {
      code: 'fr',
      label: 'France'
    } 
  ],
  defaultCountry: 'fr',
  supportedLanguages: [
    {
      code: 'fr',
      label: 'Français'
    },
    {
      code: 'en',
      label: 'English'
    } 
  ],
  defaultLanguage: 'fr',

  // Map markers
  markerAnchor: [0.5, 1],
  markerRealSize: [150.0, 200.0],
  markerDisplayScale: 0.23,
  markerOpacity: 0.95,

  // Map current position
  currentposAnchor: [0.5, 0.5],
  currentposPinpointSize: [200, 200],
  currentposPinpoinDisplayScale: 0.25,
  currentposOpacity: 1,
  currentposColor: [0, 0, 255, 1],

  // Map target position
  targetposAnchor: [0.5, 0.5],
  targetposPinpointSize: [200, 200],
  targetposPinpoinDisplayScale: 0.25,
  targetposOpacity: 1,
  targetposColor: [255, 0, 0, 1],

  // Google Analytics
  googleMeasureId: "G-31CX9B1EEN",
  googleAnalyticsDebug: false,

  // Nominatim
  nominatimEndPoint: 'https://nominatim.openstreetmap.org',

  // OSRM
  osrmEndPoint: 'https://router.project-osrm.org/route/v1/driving/',

  // Facebook
  facebookUrl: 'https://www.facebook.com/groups/junkfoodemergency'
};