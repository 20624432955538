<div id="cookieslider" *ngIf="display" class="surface-overlay absolute bottom-0 shadow-5 w-30rem h-14rem cookie-main" [style]="{'background-color': 'transparent'} ">
    <button pButton pRipple icon="pi pi-times" class="absolute right-0 p-button-rounded p-button-text p-button-plain" (click)="display=false" aria-label="Close"></button>
    <div class="flex flex-column w-full p-3 justify-content-between">
        <div class="flex flex-row justify-content-center align-items-center mb-1 cookie-header">
            <div class="flex align-items-center fa-solid fa-cookie mr-2"></div>
            <div class="flex align-items-center text-900 text-lg font-medium mr-2">{{ 'cookie.title' | translate }}</div>
            <div class="flex align-items-center fa-solid fa-cookie-bite"></div>
        </div>
        <p-tabView class="cookieslider">
            <p-tabPanel header="{{ 'cookie.info.title' | translate }}">
                <div class="overflow-y-scroll" style="height: 5rem" [innerHTML]="'cookie.info.content' | translate"></div>
            </p-tabPanel>
            <p-tabPanel header="{{ 'cookie.details.title' | translate }}">
                <div class="overflow-y-scroll" style="height: 5rem">
                    <span *ngIf="cookieConsent.analytics" [innerHTML]="'cookie.details.autorised' | translate"></span>
                    <span *ngIf="!cookieConsent.analytics" [innerHTML]="'cookie.details.denied' | translate"></span>
                </div>
            </p-tabPanel>
        </p-tabView>
        <div class="flex justify-content-center cookie-footer">
            <button pButton pRipple type="button" label="{{ 'cookie.action.deny' | translate }}" class="cookieslider-decline p-button-outlined mr-3" (click)="onDecline()" aria-label="Deny"></button>
            <button pButton pRipple type="button" label="{{ 'cookie.action.authorise' | translate }}" class="cookieslider-accept" (click)="onAuthorise()" aria-label="Grant"></button>
        </div>
    </div>
</div>
