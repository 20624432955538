// Angular
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

// PrimeNg
import { ConfirmationService, MessageService } from 'primeng/api';
import { DataService, ICompany, ISettings, IShop } from '../service/data.service';

// OpenLayers
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import OlTileLayer from 'ol/layer/Tile';
import OlOSM from 'ol/source/OSM';
import OlVectorLayer from 'ol/layer/Vector';
import OlVectorSource from 'ol/source/Vector';
import OlZoom from 'ol/control/Zoom';
import OlFeature from 'ol/Feature';
import OlPoint from 'ol/geom/Point';
import OlIcon from 'ol/style/Icon';
import { Style, Stroke } from 'ol/style';
import { Size } from 'ol/size';
import { Color } from 'ol/color';
import { defaults } from 'ol/interaction';
import { fromLonLat } from 'ol/proj';
import { Pixel } from 'ol/pixel';
import { Coordinate } from 'ol/coordinate';
import { Polyline } from 'ol/format';

// Application
import { LocateControl } from './locate-control';
import { RefreshControl } from './refresh-control';
import { BaseComponent } from '../base.component';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

export interface IRouteInfo {
  hasRoute: boolean,
  id: number,
  distance: number,
  duration: number
}

interface IFeatureParams {
  lonlat: Coordinate, 
  id: number, 
  anchor: number[], 
  src: string, 
  size: Size, 
  scale: number, 
  opacity: number, 
  zindex: number,
  color: Color
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent extends BaseComponent implements OnInit {
  // Callback to be called when a place is selected
  @Output() onMapCenterChangedLonLat: EventEmitter<number[]> = new EventEmitter<number[]>();
  // Callback to be called when the user wants to update the map with new shops
  @Output() onMapRefreshShopsRequest: EventEmitter<void> = new EventEmitter<void>();
  // Callback to be called when a marker is clicked
  @Output() onMapMarketClick: EventEmitter<number> = new EventEmitter<number>();
  // Callback to be called when a route to a shop has been found
  @Output() onMapRouteInfo: EventEmitter<IRouteInfo> = new EventEmitter<IRouteInfo>();

  // Map component (OpenLayer)
  public map!: OlMap;

  // Current position
  private currentposLayer: OlVectorLayer<OlVectorSource>;
  private currentposSource: OlVectorSource;
  private currentpos: OlFeature[] = [];
  private currentposLonLat: number[] = [];

  // Target position
  private targetposLayer: OlVectorLayer<OlVectorSource>;
  private targetposSource: OlVectorSource;
  private targetpos: OlFeature[] = [];
  private targetposLonLat: number[] = [];

  // Markers
  private markersLayer: OlVectorLayer<OlVectorSource>;
  private markersSource: OlVectorSource;
  private markers: OlFeature[] = [];

  // Route
  private routeLayer: OlVectorLayer<OlVectorSource>;
  private routeSource: OlVectorSource;
  private route: OlFeature[] = [];

  // Settings
  private settings: ISettings;

  // --------------------
  //  CONSTRUCTOR & INIT
  // --------------------

  /**
   * Constructor
   * @param http 
   * @param messageService 
   * @param confirmationService 
   * @param data 
   */
   constructor(
    protected http: HttpClient, 
    protected messageService: MessageService, 
    protected confirmationService: ConfirmationService, 
    protected data: DataService,
    protected gaService: GoogleAnalyticsService
  ) { 
    super(http, messageService, confirmationService, data, gaService);

    // Link to the settings
    this.data.settingsObservable.subscribe(settings => {
      this.settings = settings;
    });
  }

  /**
   * Build a default RouteInfo
   * @returns 
   */
  private getDefaultRouteInfo(): IRouteInfo {
    return {
      hasRoute: false,
      id: 0,
      distance: 0,
      duration: 0
    };
  }

  /**
   * Initialise
   */
  ngOnInit(): void {

    // Markers Layer
    this.markersSource = new OlVectorSource({
      features: this.markers
    });
    this.markersLayer = new OlVectorLayer({
      source: this.markersSource
    });

    // Current Position layer
    this.currentposSource = new OlVectorSource({
      features: this.currentpos
    });
    this.currentposLayer = new OlVectorLayer({
      source: this.currentposSource
    });

    // Target Position layer
    this.targetposSource = new OlVectorSource({
      features: this.targetpos
    });
    this.targetposLayer = new OlVectorLayer({
      source: this.targetposSource
    });

    // Route layer
    this.routeSource = new OlVectorSource({
      features: this.route
    });
    this.routeLayer = new OlVectorLayer({
      source: this.routeSource
    });

    // Initialise the map
    this.map = new OlMap({
      controls : [],
      layers: [
        new OlTileLayer({
          source: new OlOSM(),
        }),
        this.markersLayer,
        this.currentposLayer,
        this.targetposLayer,
        this.routeLayer
      ],
      target: 'map',
      interactions: defaults({
        keyboard: false,
        mouseWheelZoom: false
      }),
      view: new OlView({ 
        center: fromLonLat(environment.mapInitialCenter[this.settings.country_code]),
        zoom: environment.initialZoom,
        maxZoom: environment.maxZoom
      })
    });

    // Add the custom zoom control
    this.map.addControl(new OlZoom({
      className: 'jfe-map-control-zoom-position',
      zoomInLabel: '+',
      zoomOutLabel: '-'
    }));

    // Add the "locate me" control
    this.map.addControl(new LocateControl(this));

    // Add the "refresh" control
    this.map.addControl(new RefreshControl(this));

    // Pointer when on a marker
    this.map.on('pointermove', evt => {
      if (!evt.dragging) {
        let pixel: Pixel = this.map.getEventPixel(evt.originalEvent);
        let features: any[] = this.map.getFeaturesAtPixel(pixel);
        let cursor = features.find(fea => fea.getId() !== undefined) ? 'pointer' : '';
        this.map.getTargetElement().style.cursor = cursor;
      }
    });

    // Click on marker
    this.map.on('click', evt => {
      if (!evt.dragging) {
        let pixel: Pixel = this.map.getEventPixel(evt.originalEvent);
        let features: any[] = this.map.getFeaturesAtPixel(pixel);
        let feature = features.find(fea => fea.getId() !== undefined);
        if (feature) {
          this.onMapMarketClick.emit(feature.getId());
        }
      }
    });
  }

  // -------
  //  TOOLS
  // -------

  /**
   * Builds a feature
   * @param params 
   * @returns 
   */
  private buildFeature(params: IFeatureParams): OlFeature {
    let iconFeature = new OlFeature({
      geometry: new OlPoint(fromLonLat(params.lonlat)),
    });
    let iconStyle = new Style({
      image: new OlIcon({
        anchor: params.anchor,
        src: params.src,
        size: params.size,
        scale: params.scale,
        opacity: params.opacity,
        color: params.color
      })
    });
    iconStyle.setZIndex(params.zindex);
    iconFeature.setId(params.id);
    iconFeature.setStyle(iconStyle);
    return iconFeature;
  }

  // --------------
  //  DATA ACTIONS
  // --------------

  /**
   * Updates the markers
   * @param shops
   * @returns 
   */
  public buildMarkersLayers(companies: ICompany[], shops: IShop[]) {
    if (!shops) {
      return;
    }

    let markers = [];
    shops.forEach(shop => {
      let company = companies.find(c => c.id === shop.company_id);
      let iconFeature = this.buildFeature({
        lonlat: [ shop.lng, shop.lat ], 
        id: shop.id, 
        anchor: environment.markerAnchor, 
        src: "../assets/image/marker/" + company.code + ".png", 
        size: environment.markerRealSize, 
        scale: environment.markerDisplayScale, 
        opacity: environment.markerOpacity, 
        zindex : 0,
        color: undefined
      });
      markers.push(iconFeature);
    });

    this.markers = markers;
    this.markersSource.clear();
    this.markersSource.addFeatures(this.markers);
  }

  /**
   * Center the map on a point
   * @param latitude 
   * @param longitude 
   */
  public center(latitude: number, longitude: number, notify: boolean) {
    this.map.getView().animate({
      center: fromLonLat([longitude, latitude]),
      zoom: Math.max (this.map.getView().getZoom(), environment.zoomAfterCenter)
    });
    if (notify) {
      this.onMapCenterChangedLonLat.emit([longitude, latitude]);
    }
  }

  // --------------
  //  MAP CONTROLS
  // --------------

  /**
   * Center on user current location
   */
  public mapControlCenterOnCurrentLocation() {
    this.currentposLonLat = [];
    navigator.geolocation.getCurrentPosition (
        (pos) => {
            // Calls the Map component to have a unique "place centering" method
            this.center(pos.coords.latitude, pos.coords.longitude, true);
            let iconFeature = this.buildFeature({
              lonlat: [ pos.coords.longitude, pos.coords.latitude ], 
              id: undefined, 
              anchor: environment.currentposAnchor, 
              src: "../assets/image/layout/pointer.png", 
              size: environment.currentposPinpointSize, 
              scale: environment.currentposPinpoinDisplayScale, 
              opacity: environment.currentposOpacity, 
              zindex : 10001,
              color: environment.currentposColor
            });
            this.currentpos = [iconFeature];
            this.currentposSource.clear();
            this.currentposSource.addFeatures(this.currentpos);
            this.currentposLonLat = [ pos.coords.longitude, pos.coords.latitude ];
        }, 
        (err) => {
            console.warn(err.code);
            console.warn(err.message);
        }, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
    );
  }

  /**
   * Selects a shop
   * @param shop 
   */
  public selectShop(shop: IShop) {
    this.targetposLonLat = [];

    if (!shop) {
      this.targetposSource.clear();
      return;  
    }

    // Center on map
    this.center(shop.lat, shop.lng, false);

    // Add the target market
    let iconFeature = this.buildFeature({
      lonlat: [ shop.lng, shop.lat ], 
      id: shop.id, 
      anchor: environment.targetposAnchor, 
      src: "../assets/image/layout/pointer.png", 
      size: environment.targetposPinpointSize, 
      scale: environment.targetposPinpoinDisplayScale, 
      opacity: environment.currentposOpacity, 
      zindex : 10000,
      color: environment.targetposColor
    });
    this.targetpos = [iconFeature];
    this.targetposSource.clear();
    this.targetposSource.addFeatures(this.targetpos);
    this.targetposLonLat = [ shop.lng, shop.lat ];

    // ZOrder so the selected shop is on top
    this.markersSource.forEachFeature(feature => {
      let style: any = feature.getStyle();
      style.setZIndex(feature.getId() == shop.id ? 1 : 0);
    });

    this.findRoute(shop);
  }

  /**
   * Finds a route
   */
  private findRoute(shop: IShop) {
    let routeInfo: IRouteInfo = this.getDefaultRouteInfo();

    // Do we have 2 points ?
    if (this.currentposLonLat.length === 0 || this.targetposLonLat.length === 0) {
      return;
    }

    // Query OSRM API
    let url = environment.osrmEndPoint + this.currentposLonLat[0] + ',' + this.currentposLonLat[1] + ';' + this.targetposLonLat[0] + ',' + this.targetposLonLat[1] + "?overview=full";
    this.http.get<any>(url).subscribe({
      next: (result) => {
        if (result.code === "Ok" && result.routes.length>0) {
          var route = new Polyline({
              factor: 1e5
          }).readGeometry(result.routes[0].geometry, {
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:3857'
          });

          let feature = new OlFeature({
             type: 'route',
             geometry: route
          });
          let style = new Style({
            stroke: new Stroke({
              width: 6, color: [0, 0, 255, 0.4]
            })
          });

          // Add the feature
          feature.setStyle(style);
          this.routeSource.clear();
          this.routeSource.addFeature(feature);

          // Returns a routeInfo for this shop
          routeInfo.hasRoute = true;
          routeInfo.id = shop.id;
          routeInfo.distance = result.routes[0].distance;
          routeInfo.duration = result.routes[0].duration;
          this.onMapRouteInfo.emit(routeInfo);
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  /**
   * Refresh Shops Request
   */
  public mapControlRefreshShopsRequest() {
    this.onMapRefreshShopsRequest.emit();
  }
}
