<p-toast></p-toast>
<div class="min-h-screen flex flex-column surface-section">
    <!-- Start Header -->
    <div class="surface-overlay py-3 px-6 flex align-items-center justify-content-between relative lg:static">
        <img src="assets/image/layout/jfe.svg" alt="Junk Food Emergency" height="40px" width="40px" class="mr-0 lg:mr-6">
        <a pRipple class="cursor-pointer block lg:hidden text-700" pStyleClass="@next" enterClass="hidden" leaveToClass="hidden" [hideOnOutsideClick]="true">
            <i class="pi pi-bars text-4xl"></i>
        </a>
        <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
            <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                <li>
                    <a pRipple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150" (click)="onSetCurrentLocation()">
                        <i class="fa-solid fa-location-crosshairs mr-2"></i>
                        <span>{{'menu.localiseme' | translate}}</span>
                    </a>
                </li>
                <li>
                    <a pRipple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150" (click)="onMapRefreshShopsRequest()">
                        <i class="fa-solid fa-burger mr-2"></i>
                        <span>{{'menu.search' | translate}}</span>
                    </a>
                </li>
                <li>
                    <a pRipple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150" (click)="onShowSettings()">
                        <i class="fa-solid fa-gear mr-2"></i>
                        <span>{{'menu.settings' | translate}}</span>
                    </a>
                </li>
                <li>
                    <a pRipple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150" (click)="onShowCookieConsent()">
                        <i class="fa-solid fa-cookie-bite mr-2"></i>
                        <span>{{'menu.cookies' | translate}}</span>
                    </a>
                </li>
                <li>
                    <div class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center">
                        <div class="text-600 mr-2">{{'menu.country' | translate}}</div>
                        <p-dropdown [options]="environment.supportedCountries" [(ngModel)]="settings.country_code" optionLabel="label" optionValue="code" placeholder="Select a Country" (onChange)="onCountryChanged($event)">
                            <ng-template let-country pTemplate="item">
                                <div>{{country.label}}</div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </li>
                <li>
                    <div class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center">
                        <div class="text-600 mr-2">{{'menu.language' | translate}}</div>
                        <p-dropdown [options]="environment.supportedLanguages" [(ngModel)]="settings.language" optionLabel="label" optionValue="code" placeholder="Select a language" (onChange)="onLanguageChanged($event)">
                            <ng-template let-language pTemplate="item">
                                <div>{{language.label}}</div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <!-- End Header -->

    <!-- Start Companies -->
    <p-carousel class="jfe-carousel" [value]="companies" [numVisible]="12" [numScroll]="1" [responsiveOptions]="responsiveOptions">
        <ng-template pTemplate="header">
            <div class="flex flex-row p-2 justify-content-center align-items-center">
                <div class="flex align-items-center pr-2"><button pButton type="button" class="jfe-full-round-button p-button-rounded" icon="fa-regular fa-square-check" (click)="onCompanyCheckAll(true)" aria-label="Check all companies"></button></div>
                <div *ngIf="companies !== null" class="flex align-items-center pr-2 text-center text-600" [innerHTML]="'companies.selection' | translate: { 'selected': checkedCompaniesCount, 'over': companies.length }"></div>
                <div class="flex align-items-center pr-2"><button pButton type="button" class="jfe-full-round-button p-button-rounded" icon="fa-regular fa-square" (click)="onCompanyCheckAll(false)" aria-label="Uncheck all companies"></button></div>
            </div>
        </ng-template>
        <ng-template let-company pTemplate="item">
            <div class="p-1 flex flex-column flex-auto text-center jfe-company-selection" (click)="onCompanyToggle(company.id)">
                <div><img class="jfe-company-icon" src="{{ company.icon_url }}" alt="{{ company.title }}" /></div>
                <div class="text-xs text-overflow-clip white-space-nowrap">{{ company.title }}</div>
                <div *ngIf="company.checked"><i class="fa-regular fa-square-check jfe-company-icon-checkbox"></i></div>
                <div *ngIf="!company.checked"><i class="fa-regular fa-square jfe-company-icon-checkbox"></i></div>
            </div>
        </ng-template>
    </p-carousel>
    <!-- End Companies -->
    
    <div class="grid surface-section col-12">
        <!-- Start Map -->
        <div class="col-12 md:col-6 xl:col-9">
            <div class="p-0 flex flex-column flex-auto">
                <app-search class="absolute left-10 z-5" (onPlaceSelected)="onPlaceSelected($event)"></app-search>
                <app-map class="jfe-map" #mapComponent 
                    (onMapCenterChangedLonLat)="onMapCenterChangedLonLat($event)" 
                    (onMapRefreshShopsRequest)="onMapRefreshShopsRequest()" 
                    (onMapMarketClick)="onMapMarketClick($event)"
                    (onMapRouteInfo)="onMapRouteInfo($event)">
                </app-map>
            </div>
        </div>
        <!-- End Map -->

        <!-- Start Shops -->
        <div class="col-12 md:col-6 xl:col-3">
            <div class="p-2 m-0 jfe-list overflow-y-scroll" style="height: 500px;">
                <div class="text-center mb-2 font-medium">{{'results.label' | translate: { 'nb': filteredShops.length, 'radius': settings.search_radius } }}</div>
                <ul class="list-none p-0 m-0">
                    <li *ngFor="let shop of filteredShops" class="flex flex-row py-0 border-top-1 border-bottom-1 surface-border" [ngClass]="{'jfe-list-selected': selectedShop?.id === shop.id }">
                        <div id="shop_{{ shop.id }}" class="flex-column py-2 flex-none p-2 mr-3">
                            <div><img src="{{ shop.icon_url }}" class="w-4rem h-4rem align-self-center" alt="{{ shop.name }}" /></div>
                            <div class="text-orange-600 text-center font-medium">
                                {{ shop.distance_str }}km
                            </div>
                        </div>
                        <div class="flex-column py-2 flex-grow-1">
                            <div class="text-900 font-medium">{{ shop.name }}</div>
                            <div class="text-600">{{ shop.address }}</div>
                            <div class="text-600">{{ shop.postcode }} {{ shop.city }}</div>
                            <div class="jfe-list-route font-medium" *ngIf="shop.real_distance_str !== '' && shop.duration_str !== ''">{{ shop.real_distance_str }}km ({{'results.distance' | translate}}), {{ shop.duration_str }} ({{'results.duration' | translate}})</div>
                        </div>
                        <div class="flex-column p-1 flex-none align-self-center">
                            <div class="pb-2"><button pButton type="button" class="jfe-outlined-round-button p-button-outlined p-button-rounded" icon="fa-solid fa-map-location-dot" (click)="onShopLocate(shop)" aria-label="Locate restaurant on map"></button></div>
                            <!-- <div><button pButton type="button" class="jfe-outlined-round-button p-button-outlined p-button-rounded" icon="fa-solid fa-question" aria-label="Restaurant information"></button></div> -->
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- End Shops -->
    </div>

    <!-- Start Footer -->
    <div class="surface-section p-2">
        <div class="flex flex-row align-items-center justify-content-around">
            <a class="cursor-pointer text-blue-800 transition-colors transition-duration-150 hover:text-700" href="{{ environment.facebookUrl }}" target="_blank"><i class="pi pi-facebook text-4xl"></i></a>
            <a pRipple class="cursor-pointer block text-700 py-2 px-3 transition-colors transition-duration-150 hover:surface-300 border-round hover:text-900" (click)="displayLegal=true">{{'legal.link_label' | translate}}</a>
        </div>
    </div>
    <!-- End Footer -->
</div>

<!-- Start Settings Dialog Box -->
<p-dialog [(visible)]="displaySettings" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '40vw'}" header="Paramètres" [draggable]="false" [resizable]="false">
    <div class="flex flex-wrap border-top-1 surface-border pt-4">
        <div class="flex align-items-center justify-content-center py-3 px-0 w-full md:w-4 border-round">
            <img src="assets/image/layout/jfe.svg" alt="Junk Food Emergency" class="w-9">
        </div>
        <div class="flex flex-column">
            <div class="text-700 m-1">Rayon de recherche (km)</div>
            <div class="m-1"><input type="text" pInputText [(ngModel)]="settingsRadius" readonly/></div>
            <p-slider class="m-1" [(ngModel)]="settingsRadius" [min]="5" [max]="200"></p-slider>
            <div class="mb-4"></div>
            <div class="text-700 m-1">Nombre maximum de résultats</div>
            <div class="m-1"><input type="text" pInputText [(ngModel)]="settingsMaxResults" readonly/></div>
            <p-slider class="m-1" [(ngModel)]="settingsMaxResults" [min]="10" [max]="100"></p-slider>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="border-top-1 surface-border pt-3">
            <p-button icon="pi pi-check" (click)="onSaveSettings()" label="Enregistrer" styleClass="p-button-text" aria-label="Save settings"></p-button>
        </div>
    </ng-template>
</p-dialog>
<!-- End Settings Dialog Box -->

<!-- Start Legal -->
<p-dialog header="{{'legal.title' | translate}}" [(visible)]="displayLegal" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '40vw'}">
    <div class="border-top-1 surface-border pt-4">
        <strong>{{'legal.editor.title' | translate}}</strong><br />
        <div [innerHTML]="'legal.editor.content' | translate"></div><br />
        
        <strong>{{'legal.hosting.title' | translate}}</strong><br />
        <div [innerHTML]="'legal.hosting.content' | translate"></div><br />
    
        <strong>{{'legal.termsAndConditions.title' | translate}}</strong><br />
        <div [innerHTML]="'legal.termsAndConditions.content' | translate"></div><br /> 
    
        <strong>{{'legal.privacyAndCookies.title' | translate}}</strong><br />
        <div [innerHTML]="'legal.privacyAndCookies.content' | translate"></div><br />     
    </div>

    <ng-template pTemplate="footer">
        <div class="border-top-1 surface-border pt-3">
            <p-button icon="pi pi-check" (click)="displayLegal=false" label="Ok" styleClass="p-button-text" aria-label="Close legal dialog"></p-button>
        </div>
    </ng-template>
</p-dialog>
<!-- End Legal -->

<!-- Start Cookie consent -->
<app-cookie #cookieComponent></app-cookie>
<!-- End Cookie consent -->
